<template>
  <div v-if="position === 'center'" class="follow-bg-wrap d-block">
    <div class="follow-bg"></div>
  </div>
  <div v-else-if="position === 'left'" class="home-blog-bg-wrap d-block">
    <div class="home-blog-bg"></div>
  </div>
  <div v-else-if="position === 'right'" class="cta-bg-wrap d-block">
    <div class="cta-bg"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
  },
  position: { type: String, required: true },
  size: {
    type: [String, Number],
    default: null,
  },
  width: {
    type: [String, Number],
    default: null,
  },
  top: {
    type: [String, Number],
    default: null,
  },
  right: {
    type: [String, Number],
    default: null,
  },
});

const height = computed(() =>
  props.blok?.gradient_size || props.size
    ? `${props.blok?.gradient_size || props.size}%`
    : "auto",
);
const width = computed(() =>
  props.blok?.gradient_width || props.width ? `${props.width}%` : "auto",
);
const top = computed(() => `${props.blok?.gradient_top || props.top || 0}%`);
const right = computed(
  () => `${props.blok?.gradient_right || props.right || 0}%`,
);
</script>

<style scoped>
.follow-bg {
  height: v-bind(height);
  top: v-bind(top);
  right: v-bind(right);
  min-width: auto;
  min-height: 100%;
}
.cta-bg {
  height: auto;
  width: v-bind(width);
}
</style>
